import Grid from '@mui/material/Grid'

export default function Container({ children }) {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
      sx={{ marginTop: '64px', minHeight: 'calc(100vh - 64px)' }}
    >
      {children}
    </Grid>
  )
}
