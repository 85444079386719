import Container from 'src/components/Container'
import AppBar from 'src/components/AppBar'

const MainLayout = ({ children }) => {
  return (
    <>
      <AppBar />
      <Container>{children}</Container>
    </>
  )
}

export default MainLayout
