import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#78909c',
    },
    secondary: {
      main: '#f50057',
    },
  },
})

import './index.css'

const App = () => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <RedwoodApolloProvider>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </RedwoodApolloProvider>
    </RedwoodProvider>
  </FatalErrorBoundary>
)

export default App
