import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { NavLink, routes } from '@redwoodjs/router'
import { styled } from '@mui/material/styles'

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  '&.error': {
    color: theme.palette.text.secondary,
  },
}))

export default function ButtonAppBar() {
  return (
    <Box sx={{ flexGrow: 1, position: 'fixed', width: '100%', zIndex: 10 }}>
      <AppBar position="static">
        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h6" component="div" sx={{ padding: '0 30px' }}>
            <StyledNavLink activeClassName="error" to={routes.home()}>
              Home
            </StyledNavLink>
          </Typography>
          <Typography variant="h6" component="div" sx={{ padding: '0 30px' }}>
            <StyledNavLink activeClassName="error" to={routes.work()}>
              Work
            </StyledNavLink>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
